<!-- @format -->

<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import moment from "moment";
import AlertWidget from "@/components/general/AlertWidget";
// import pagination from "@/views/pages/admin/calendar/reuseableComponents/pagination.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Employee Salary Invoice",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    AlertWidget,
    // pagination,
    DateRangePicker
  },

  data() {
    return {
      title: "Employee Salary Invoice",
      table_data: {
        from: null,
        to: null,
        status : 'Completed'
      },
      employee : [],
      shift_timelines : [],
      // paginationData: null,
      date_range: {
        startDate: null,
        endDate: null,
      },
    };
  },

  watch: {
    "date_range.endDate": {
      handler: function (new_data, old_data) {
        if (new_data) {
          this.table_data.from = this.formatDateYMD(this.date_range.startDate);
          this.table_data.to = this.formatDateYMD(this.date_range.endDate);
          this.fetchEmployeePayroll();
        }
      },
    },
  },
  
  filters: {
    date(val) {
      return val ? moment(val).format("YYYY-MM-DD") : "";
    },
  },

  methods: {
    fetchEmployeePayroll() {
      this.$axios
      .get("employee/team-member/payroll/" + this.$route.params.employee_id,{params: this.table_data})
      .then((response) => {
        let data = response.data.payload;
        this.employee = data;
        this.shift_timelines = data.shift_timelines;
      });
    },

    checkIsCompleted(timelineTo) {
      let status = 'Accepted'
      if(moment(this.DateWithMonthName(new Date())).isSameOrAfter(this.DateWithMonthName(timelineTo)))
      status = 'Completed';
      return status;
    },

    rolesToString(areaRoles) {
      let name_array = areaRoles.map((role) => {
        return role.name;
      });
      let string = name_array.join(', ').toString();

      return string;
    },
    print(){
      window.print();
    },
  },
  created() {
    this.table_data.from = this.formatDateYMD(moment().startOf("month").toDate());
    this.table_data.to = this.formatDateYMD(moment().endOf("month").toDate());
    this.fetchEmployeePayroll();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-6">
        <PageHeader :title="title" />
      </div>
      <div class="col-md-6">
      <label>Select Date</label>
        <date-range-picker
          class="bg-light w-100"
          ref="picker"
          :opens="'right'"
          :locale-data="{ format: 'mm/dd/yyyy' }"
          :singleDatePicker="false"
          :timePicker="false"
          :timePicker24Hour="false"
          :showWeekNumbers="true"
          :showDropdowns="true"
          :autoApply="false"
          v-model="date_range"
        >
          <template v-slot:input="picker" style="min-width: 350px">
            {{ picker.startDate | date }} - {{ picker.endDate | date }}
          </template>
        </date-range-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="col-lg-12 col-md-12 text-end align-self-center">
              <div  class="dropdown-btn d-flex align-items-end justify-content-end h-100" >
                <button class="btn text-white" @click="print">Download Invoice</button>
              </div>
            </div>
            <div class="row single-employee-card py-3">
              <div class="col-lg-3 col-md-12 align-self-center">
                <div>
                  <div
                    class="d-flex flex-lg-row flex-md-column justify-content-lg-start justify-content-md-center"
                  >
                    <div>
                      <img
                        :src=" getSafe(()=> employee.user.avatar == null ? employee.user.avatar_path : assets_url + employee.user.avatar)"
                        class="avatar-md rounded-circle me-2"
                      />
                    </div>
                    <div>
                      <h2 class="mb-0 text-capitalize text-dark fw-bold">{{ getSafe(()=> employee.user.name)}}</h2>
                      <span class="text-muted text-capitalize">Availability : {{ getSafe(()=> employee.employee_type)}}</span>
                      <p><span class="secondaryColor text-capitalize">{{getSafe(()=> rolesToString(employee.area_roles))}}</span></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-3 align-self-center">
                <div class="mx-auto cell-width">
                  <h2 class="mb-0 text-dark fw-bold">{{getSafe(()=> employee.total_hours)}}hrs</h2>
                  <span class="primaryColor">{{getSafe(()=> employee.total_hours)}} hours and {{getSafe(()=> employee.total_mintues)}} mints</span>
                  <p class="mb-0 text-muted">Total Working Hrs</p>
                  <p class="mb-0 secondaryColor" >This Month</p>
                </div>
              </div>
              <div class="col-lg-2 col-md-3 align-self-center">
                <div class="mx-auto cell-width">
                  <h2 class="mb-0 text-dark fw-bold">${{ getSafe(()=> employee.salary)}}</h2>
                  <span class="primaryColor">{{getSafe(()=> employee.payment) ? '$'+employee.payment + ' base salary per-hour' : 'Pay Role per-Hour'}}</span>
                  <p class="mb-0 text-muted">Total Salary</p>
                  <p class="mb-0 secondaryColor">This Month</p>
                </div>
              </div>
              <div class="col-lg-2 col-md-3 align-self-center">
                <div class="mx-auto cell-width">
                  <h2 class="mb-0 text-dark fw-bold">{{getSafe(()=> employee.premiumShiftsCount)}} Shifts</h2>
                  <span class="primaryColor">Premium Shifts</span>
                  <p class="mb-0 text-muted">Total Shifts</p>
                  <p class="mb-0 secondaryColor" >This Month</p>
                </div>
              </div>

              <div class="col-lg-2 col-md-3 align-self-center">
                <div class="mx-auto cell-width">
                  <h2 class="mb-0 text-dark fw-bold">{{getSafe(()=> shift_timelines.length)}} Shifts</h2>
                  <span class="primaryColor">Completed Shift</span>
                  <p class="mb-0 text-muted">Total Shifts</p>
                  <p class="mb-0 secondaryColor">This Month</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card roboto">
      <div class="card-body">
        <div class="row col-12">
          <div class="col-md-12 mb-0">
            <div class="my-3 table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="text-capitalize">Shift Name</th>
                    <th class="text-capitalize text-center">
                      Assigned Location
                    </th>
                    <th class="text-capitalize">Shift Status</th>
                    <th class="text-capitalize text-center">Shift From</th>
                    <th class="text-capitalize text-center">Shift To</th>
                    <th class="text-capitalize text-center">Shift Hours</th>
                    <th class="text-capitalize text-center">Role/Position </th>
                    <th class="text-capitalize text-center">{{getSafe(()=> employee.payment) ? ' Base salary per-hour' : 'Pay Role per-Hour'}} </th>
                    <th class="text-capitalize text-center">
                      Shift Type
                    </th>
                    <th class="text-capitalize text-center">Bonus Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(timeline, index) in shift_timelines" :key="index">
                    <td class="align-middle">
                      <div>
                        <span>{{ getSafe(()=> timeline.shift.name )}}</span>
                      </div>
                    </td>
                    <td class="align-middle  text-center">
                      {{ getSafe(()=> timeline.shift.location.name) }}
                    </td>
                    <td class="align-middle">
                      {{ getSafe(()=> checkIsCompleted(timeline.to)  ) }}
                    </td>
                    <td class="align-middle  text-center">
                      {{ getSafe(()=>  DateWithMonthName(timeline.from))}}
                    </td>
                    <td class="align-middle  text-center">
                      {{ getSafe(()=>  DateWithMonthName(timeline.to) )}}
                    </td>
                    <td class="align-middle  text-center text-danger">
                      {{ getSafe(()=>  timeline.shift_hours[0] + ' Hrs, ' + timeline.shift_hours[1] + ' mints' )}}
                    </td>
                    <td class="align-middle  text-center">
                      {{ getSafe(()=> timeline.area_role.name) }}
                    </td>
                    <td class="align-middle  text-center">
                      {{ getSafe(()=> employee.payment) ? '$'+employee.payment  : '$'+timeline.area_role.payment}}
                    </td>
                    <td class="align-middle text-center">
                      <span  class="primaryColor" :class="{secondaryColor : timeline.shift.shift_type =='Premium'} "> {{
                        getSafe(()=> timeline.shift.shift_type )
                      }}</span>
                    </td>
                    <td class="align-middle  text-center">
                      <span>{{ getSafe(()=>  timeline.shift.bonus ) ? '$'+getSafe(()=> timeline.shift.bonus) : '$0' }}</span>
                    </td>
                  </tr>
                  
                   <tr v-if="shift_timelines.length == 0">
                      <td colspan="12">
                        <AlertWidget
                          class="mt-4"
                          text="No Record Found"
                        ></AlertWidget>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- <div class="row">
           <pagination
              :paginationProp="paginationData"
              @next="fetchEmployeePayroll"
            />
        </div> -->
      </div>
    </div>
  </Layout>
</template>
